import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import CreateMembershipRequestModel from '../../generated/types/model/resource/CreateMembershipRequestModel';
import MembershipTemplateResourceViewModel from './MembershipTemplateResourceViewModel';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';
import RequestSummarySectionResourceViewModel from './RequestSummarySectionResourceViewModel';

export default class CreateMembershipRequestViewModel extends CreateMembershipRequestModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  protected transformToDTO() {
    this.dto.summary = this.summary;
    (this.dto as any).chosenMembershipTemplate = this.chosenMembershipTemplate?.toDTO();
  }

  protected transformFromDTO() {
    this.chosenMembershipTemplate = this.chosenMembershipTemplate ?? new MembershipTemplateResourceViewModel();
    const dto = this.dto as any;
    this.chosenMembershipTemplate.fromDTO(dto?.chosenMembershipTemplate ?? {});
  }

  chosenMembershipTemplate?: MembershipTemplateResourceViewModel;

  get chosenMembershipCaption() { return `${this.chosenMembershipTemplate?.caption}`; }

  get membershipIdFromQuery() { return this.getFromAttributeOrQuery('membershipid'); }

  get userLanguageFromQuery() { return this.getFromAttributeOrQuery('language'); }

  get recruitingBusinessUnitNumberFromQuery() { return Vue._.isSet(this.getFromAttributeOrQuery('recruitingBusinessUnitNumber')) ? Number.parseInt(this.getFromAttributeOrQuery('recruitingBusinessUnitNumber')!, 10) : undefined; }

  get admissionReasonNumberFromQuery() { return Vue._.isSet(this.getFromAttributeOrQuery('admissionReasonNumber')) ? Number.parseInt(this.getFromAttributeOrQuery('admissionReasonNumber')!, 10) : undefined; }

  get admissionMotivationReasonNumberFromQuery() { return Vue._.isSet(this.getFromAttributeOrQuery('admissionMotivationReasonNumber')) ? Number.parseInt(this.getFromAttributeOrQuery('admissionMotivationReasonNumber')!, 10) : undefined; }

  get sourceCampaignNumberFromQuery() { return Vue._.isSet(this.getFromAttributeOrQuery('source-campaign-number-default')) ? Number.parseInt(this.getFromAttributeOrQuery('source-campaign-number-default')!, 10) : undefined; }

  get membershipAmountFromQuery() { return Vue._.isSet(this.getFromAttributeOrQuery('membershipamount')) ? Number.parseInt(this.getFromAttributeOrQuery('membershipamount')!, 10) : undefined; }

  get paymentMethodFromQuery() { return Vue._.isSet(this.getFromAttributeOrQuery('paymentmethod')) ? Number.parseInt(this.getFromAttributeOrQuery('paymentmethod')!, 10) : undefined; }

  get isGiftFromQuery() { return Vue._.isSet(this.getFromAttributeOrQuery('isgift')) ? this.getFromAttributeOrQuery('isgift') : undefined; }

  getFromAttributeOrQuery(key: string): string | undefined {
    key = key.toLowerCase();
    const queries = Vue.$routerUtils.getQueryParams();
    const queriesLowerCase = {} as any;
    Object.keys(queries).forEach((queryKey) => {
      queriesLowerCase[queryKey.toLowerCase()] = queries[queryKey];
    });
    const configKey = `widget-${key}`;
    const value = (Vue.$config.values as any)[configKey] ?? Vue.$router.currentRoute.params[key] ?? queriesLowerCase[key];
    return value;
  }

  get summary() {
    return new RequestSummaryResourceViewModel().fromModel({
      sections: [
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.membershipwizard.membership_overview'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.chosenMembershipTemplate?.caption,
              label: Vue.$t('pronatura.widget.membershipwizard.membership_requestsummary_title'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: `CHF ${this.membershipAmount}${((this.membershipAmount ?? 0) - Math.floor((this.membershipAmount ?? 0)) !== 0 ? '' : '.—')}`,
              label: Vue.$t('pronatura.widget.membershipwizard.amount_title'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.isGiftMembership ? Vue.$t('core.app.yes') : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.gift_yes'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.acceptNewsletter ? Vue.$t('core.app.yes') : undefined,
              label: 'Newsletter',
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: Vue.$tAlt(`pronatura.widget.membershipwizard.payment_option_${this.paymentMethod}`, undefined),
              label: Vue.$t('pronatura.widget.membershipwizard.payment_selected'),
              showLabelOnMobile: true,
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.membershipwizard.billing_adress'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: Vue.$t(`core.address.salutationEnum${this.billingContact.address.salutation}`),
              label: Vue.$t('core.address.salutation'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: `${this.billingContact.address.firstName} ${this.billingContact.address.lastName}`,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_name'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingContact.address.houseNumber ? `${this.billingContact.address.street} ${this.billingContact.address.houseNumber}` : this.billingContact.address.street,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_street'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingContact.address.addressLine1,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_suffix'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: `${this.billingContact.address.postalCode} ${this.billingContact.address.city}`,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_city'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: Vue.$t(`pronatura.widget.membershipwizard.customer_country_${this.billingContact.address.countryIso2}`),
              label: Vue.$t('pronatura.widget.membershipwizard.customer_country'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingContact.address.eMail,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_email'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingContact.address.dateOfBirth ? Vue.$format.localDate(this.billingContact.address.dateOfBirth) : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_dob'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingContact.address.phoneNumber,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_phone'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: Vue.$t(`core.page.language.${this.billingContact.languageIso2?.toLowerCase()}`),
              label: Vue.$t('pronatura.widget.membershipwizard.language_title'),
              showLabelOnMobile: true,
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.membershipwizard.donee_title'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: Vue.$tAlt(`core.address.salutationEnum${this.memberContact?.address.salutation}`, undefined),
              label: Vue.$t('core.address.salutation'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.memberContact?.address.firstName && this.memberContact?.address.lastName ? `${this.memberContact?.address.firstName} ${this.memberContact?.address.lastName}` : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_name'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.memberContact?.address.houseNumber ? `${this.memberContact?.address.street} ${this.memberContact?.address.houseNumber}` : this.memberContact?.address.street,
              label: Vue.$t('pronatura.widget.membershipwizard.donee_street'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.memberContact?.address.addressLine1,
              label: Vue.$t('pronatura.widget.membershipwizard.donee_suffix'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.memberContact?.address.postalCode && this.memberContact?.address.city ? `${this.memberContact?.address.postalCode} ${this.memberContact?.address.city}` : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.donee_city'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: Vue.$tAlt(`pronatura.widget.membershipwizard.customer_country_${this.memberContact?.address.countryIso2}`, undefined),
              label: Vue.$t('pronatura.widget.membershipwizard.donee_country'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.memberContact?.address.dateOfBirth ? Vue.$format.localDate(this.memberContact?.address.dateOfBirth) : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.donee_dob'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.memberContact?.address.eMail,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_email'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.memberContact?.languageIso2 ? Vue.$t(`core.page.language.${this.memberContact.languageIso2.toLowerCase()}`) : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.language_title'),
              showLabelOnMobile: true,
            }),

          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.membershipwizard.customer2_title'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.partnerContact?.address.firstName && this.partnerContact?.address.lastName ? Vue.$t(`core.address.salutationEnum${this.partnerContact?.address.salutation}`) : undefined,
              label: Vue.$t('core.address.salutation'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.partnerContact?.address.firstName && this.partnerContact?.address.lastName ? `${this.partnerContact?.address.firstName} ${this.partnerContact?.address.lastName}` : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_name'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.partnerContact?.address.eMail,
              label: Vue.$t('pronatura.widget.membershipwizard.customer_email'),
              showLabelOnMobile: false,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.partnerContact?.address.firstName && this.partnerContact?.address.lastName && this.partnerContact?.address.dateOfBirth ? Vue.$format.localDate(this.partnerContact?.address.dateOfBirth) : undefined,
              label: Vue.$t('pronatura.widget.membershipwizard.customer2_dob'),
              showLabelOnMobile: true,
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
      ].filter((section) => section.items.length !== 0),
    });
  }
}
