<template>
  <prona-wizard-step class="wizard-step-membership-contactdata">
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <h4>{{ $t('pronatura.widget.membershipwizard.billing_adress') }}</h4>
    <div class="pronatura-membership-form">
      <p-row>
        <p-col xs12 md5>
          <p-select v-model="wizardData.billingContact.address.salutation" :label="$t('core.address.salutation')" :items="salutationSelectItems" required></p-select>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md6>
          <p-text-field v-model="wizardData.billingContact.address.firstName" :label="$t('pronatura.widget.membershipwizard.customer_first_name')" required></p-text-field>
        </p-col>
        <p-col xs12 md6>
          <p-text-field v-model="wizardData.billingContact.address.lastName" :label="$t('pronatura.widget.membershipwizard.customer_last_name')" required></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md10>
          <p-text-field v-model="wizardData.billingContact.address.street" :label="$t('pronatura.widget.membershipwizard.customer_street')" required></p-text-field>
        </p-col>
        <p-col xs12 md2>
          <p-text-field v-model="wizardData.billingContact.address.houseNumber" :label="$t('pronatura.widget.membershipwizard.customer_street_number')"></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-text-field v-model="wizardData.billingContact.address.addressLine1" :label="$t('pronatura.widget.membershipwizard.customer_suffix')"></p-text-field>
        </p-col>
      </p-row>
      <p-city-field v-model="wizardData.billingContact.address" required></p-city-field>
      <p-row>
        <p-col xs12 md5>
          <p-select v-model="wizardData.billingContact.address.countryIso2" :label="$t('pronatura.widget.membershipwizard.customer_country')" :items="countryLookupItems" required></p-select>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 lg2>
          <p-date-field v-model="wizardData.billingContact.address.dateOfBirth" display-formatter="D.M.YYYY" :label="$t('pronatura.widget.membershipwizard.customer_dob')" :placeholder="$format.localDate($date.now())" :max="wizardData.billingContact.address.dateOfBirthMax"></p-date-field>
        </p-col>
      </p-row>
      <h4>{{ $t('pronatura.widget.membershipwizard.comunication_data') }}</h4>
      <p-row>
        <p-col xs12>
          <p-text-field v-model="wizardData.billingContact.address.eMail" :label="$t('pronatura.widget.membershipwizard.customer_email')" required></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-phone-field v-model="wizardData.billingContact.address.phoneNumber" :label="$t('pronatura.widget.membershipwizard.customer_phone')"></p-phone-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md5>
          <p-select v-model="wizardData.billingContact.languageIso2" :label="$t('pronatura.widget.membershipwizard.language_title')" :items="correspondanceLanguage" required></p-select>
          <p v-if="$translation.get() === 'it'" class="caption-1 my-2">
            {{ $t('pronatura.widget.membershipwizard.customer_language') }}
          </p>
        </p-col>
      </p-row>
      <p-checkbox v-model="wizardData.acceptNewsletter" :label="$t('pronatura.widget.membershipwizard.customer_marketing')">
      </p-checkbox>
    </div>
  </prona-wizard-step>
</template>

<script lang="ts">
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import MembershipTemplateResourceViewModel from '@/src/services/v1/viewModel/resource/MembershipTemplateResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardStepMembershipContactdata',
    props: {
      value: { type: Object, default: undefined },
    },
    data: () => ({
      isLoading: false,
      salutationSelectItems: [
        { caption: Vue.$t('core.address.salutationEnum1'), id: 1 },
        { caption: Vue.$t('core.address.salutationEnum2'), id: 2 },
        { caption: Vue.$t('core.address.salutationEnum3'), id: 3 },
      ],
      countryLookupItems: [
        { caption: Vue.$t('pronatura.widget.membershipwizard.customer_country_CH'), id: 'CH' },
        { caption: Vue.$t('pronatura.widget.membershipwizard.customer_country_DE'), id: 'DE' },
        { caption: Vue.$t('pronatura.widget.membershipwizard.customer_country_AT'), id: 'AT' },
        { caption: Vue.$t('pronatura.widget.membershipwizard.customer_country_IT'), id: 'IT' },
        { caption: Vue.$t('pronatura.widget.membershipwizard.customer_country_FR'), id: 'FR' },
      ],
      correspondanceLanguage: [
        { caption: Vue.$t('core.page.language.de'), id: 'DE' },
        { caption: Vue.$t('core.page.language.fr'), id: 'FR' },
      ],
      error: undefined as any,
      selectedMembership: undefined as undefined | MembershipTemplateResourceViewModel,
    }),
    computed: {
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.value ?? {};
        },
        set(value: CreateMembershipRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      if (!this._.isSet(this.wizardData.billingContact.address.countryIso2)) {
        this.wizardData.billingContact.address.countryIso2 = 'CH';
      }
      if (!this._.isSet(this.wizardData.billingContact.languageIso2)) {
        const userLanguage = this.$translation.get();
        if (userLanguage === 'fr') {
          this.wizardData.billingContact.languageIso2 = 'FR';
        } else {
          this.wizardData.billingContact.languageIso2 = 'DE';
        }
      }
      this.isLoading = false;
    },
  });
</script>
