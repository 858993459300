import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  ...queryBuilder.properties({
    billingContact: {
      type: 'object',
      properties: {
        address: {
          type: 'object',
          properties: {
            salutation: {
              required: true, type: 'integer',
            },
            firstName: {
              required: true, type: 'string',
            },
            lastName: {
              required: true, type: 'string',
            },
            street: {
              required: true, type: 'string',
            },
            postalCode: {
              required: true, type: 'string',
            },
            city: {
              required: true, type: 'string',
            },
            eMail: {
              required: true, type: 'string', pattern: validationPatterns.email,
            },
            phoneNumber: {
              required: false, type: 'string', pattern: validationPatterns.phone,
            },
          },
        },
      },
    },

  }),
};
