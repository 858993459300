<template>
  <p-container class="widget form fill-height">
    <p-row column no-gutters nowrap>
      <p-col shrink>
        <h2>{{ $t('pronatura.widget.membership-registration-config.title') }}</h2>
      </p-col>
      <p-col shrink>
        <p-card>
          <p-row>
            <p-col xs12 md3 lg3>
              <p-select
                v-model="widgetConfig.language"
                :label="$t('page.settings.title.languageSettings')"
                :items="languages"
              ></p-select>
            </p-col>
            <p-col xs12 md2 lg2 xl1>
              <p-number-field
                v-model.number="widgetConfig['widget-recruitingBusinessUnitNumber']"
                :label="$t('pronatura.widget.membership-registration-config.recruitingBusinessUnitNumber')"
                no-steppers
                :disabled="!$auth.isInRole('HostAdmin')"
              ></p-number-field>
            </p-col>
            <p-col xs12 md4 lg5 xl4>
              <p-select
                v-model="widgetConfig['widget-membershipid']"
                :label="$t('pronatura.widget.membership-registration-config.membership')"
                :items="memberships"
              ></p-select>
            </p-col>
            <p-col></p-col>
          </p-row>
          <p-row>
            <p-col>
              <p-number-field
                v-model.number="widgetConfig['widget-membershipamount']"
                :label="$t('pronatura.widget.membershipwizard.amount_title')"
                no-steppers
                :disabled="!$auth.isInRole('HostAdmin')"
              ></p-number-field>
            </p-col>
            <p-col>
              <p-select
                v-model="widgetConfig['widget-paymentmethod']"
                name="payment-option"
                :label="$t('pronatura.widget.membershipwizard.payment_title')"
                :items="paymentTypeList"
              ></p-select>
            </p-col>
            <p-col>
              <p-checkbox
                v-model="widgetConfig['widget-isgift']"
                :label="$t('pronatura.widget.membershipwizard.gift_yes')"
                class="mt-4"
              ></p-checkbox>
            </p-col>
            <p-col :xs12="!$media.isDesktop" :justify-end="!$media.isDesktop" align-end>
              <p-button @click="openWidget(widgetConfig['widget-membershipid'], widgetConfig['widget-recruitingBusinessUnitNumber'], widgetConfig.language, widgetConfig['widget-membershipamount'], widgetConfig['widget-paymentmethod'], widgetConfig['widget-isgift'])">
                {{ $t('core.app.ok') }}
              </p-button>
              <p-button :title="$t('widget.config-pages.embed-info.hint')" class="ml-2" secondary @click="toggleWidgetEmbedInfo()">
                <p-icon :color="isWidgetEmbedInfoVisible ? 'base' : undefined">
                  dataset_linked
                </p-icon>
                <p-icon right>
                  {{ isWidgetEmbedInfoVisible ? 'expand_less' : 'expand_more' }}
                </p-icon>
              </p-button>
            </p-col>
          </p-row>
        </p-card>
      </p-col>
      <p-card v-if="isWidgetEmbedInfoVisible" class="ma-0">
        <div v-if="!link">
          {{ $t('widget.config-pages.embed-info.no-link-message') }}
        </div>
        <template v-else>
          <p-col shrink>
            <p-row align-center>
              <p-col shrink>
                <a ref="link" :href="link" target="_blank" rel="noopener noreferrer">{{ link }}</a>
              </p-col>
              <p-col grow>
                <p-row dense>
                  <p-button secondary @click="_.copyToClipboard($refs.link)">
                    <p-icon>content_copy</p-icon>
                  </p-button>
                </p-row>
              </p-col>
            </p-row>
          </p-col>
          <p-divider class="mb-4"></p-divider>
          <p-col>
            <p-row>
              <p-col grow>
                <pre ref="widgetEmbedCode" class="code overflow-auto">{{ widgetEmbedSnippet }}</pre>
              </p-col>
              <p-col shrink :xs12="!$media.isDesktop">
                <p-button secondary @click="_.copyToClipboard($refs.widgetEmbedCode)">
                  <p-icon>content_copy</p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-col>
        </template>
      </p-card>
      <template v-if="link">
        <p-col>
          <div id="result" class="fill-height fill-width px-5 pt-4">
            <!-- WIDGET RENDERED HERE -->
          </div>
        </p-col>
      </template>
    </p-row>
  </p-container>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    role: ['Admin', 'HostAdmin'],
    data: () => ({
      isWidgetEmbedInfoVisible: false,
      widgetHost: `${window.location.protocol}//${window.location.host}`,
      widgetConfig: {
        'mount-id': 'pronatura-membership-registration',
        route: '_widgets/membership/registration',
        language: 'de',
        'widget-recruitingBusinessUnitNumber': undefined as number | undefined,
        'widget-membershipid': undefined as string | undefined,
        'widget-membershipamount': undefined as number | undefined,
        'widget-paymentmethod': undefined as number | undefined,
        'widget-isgift': false as boolean | undefined,
      },
      widgetEmbedSnippet: undefined as string | undefined,
      link: undefined as string | undefined,
      paymentTypeList: [
        { id: 6, caption: Vue.$t('pronatura.widget.membershipwizard.payment_online') },
        { id: 1, caption: Vue.$t('pronatura.widget.membershipwizard.payment_invoice') },
      ],
    }),
    computed: {
      languages(): { id: string, caption: string, description: string }[] {
        return this.$translation.availableAppLanguages.map((lang) => ({
          id: lang.toLowerCase(),
          caption: this.$t(`core.page.language.${lang.toLowerCase()}`),
          description: lang.toLowerCase(),
        }));
      },
      memberships(): { id: string, caption: string, description: string }[] {
        const memberships: { id: string, caption: string, description: string }[] = [];
        memberships.push({ id: '15efeafe-6e43-472f-922e-ea8e01b1901c', caption: this.$t('pronatura.widget.membershipwizard.memberschip_einzelmitgliedschaft'), description: this.$t('pronatura.widget.membershipwizard.memberschip_einzelmitgliedschaft') });
        memberships.push({ id: 'ded73b60-85c3-4d6f-ad01-13e9f7b12df4', caption: this.$t('pronatura.widget.membershipwizard.membership_paarmitgliedschaft'), description: this.$t('pronatura.widget.membershipwizard.membership_paarmitgliedschaft') });
        memberships.push({ id: '1e531bc9-a59d-410d-8498-2510609973cd', caption: this.$t('pronatura.widget.membershipwizard.membership_familienmitgliedschaft'), description: this.$t('pronatura.widget.membershipwizard.membership_familienmitgliedschaft') });
        memberships.push({ id: '88522b68-62d7-4e91-814c-8674b6774d1c', caption: this.$t('pronatura.widget.membershipwizard.membership_kinder'), description: this.$t('pronatura.widget.membershipwizard.membership_kinder') });
        memberships.push({ id: 'ca397c86-a964-401a-bce8-ba536bebd3ab', caption: this.$t('pronatura.widget.membershipwizard.membership_jugendliche'), description: this.$t('pronatura.widget.membershipwizard.membership_jugendliche') });
        memberships.push({ id: '18b68f6b-5787-493f-8109-4a29857a4801', caption: this.$t('pronatura.widget.membershipwizard.membership_mitgliedschaft_plus'), description: this.$t('pronatura.widget.membershipwizard.membership_mitgliedschaft_plus') });
        return memberships;
      },
    },
    mounted() {
      this.widgetConfig.language = this.getQueryOrValue('language') ?? this.widgetConfig.language;
      const recruitingBusinessUnitNumber = this.getQueryOrValue('widget-recruitingBusinessUnitNumber', 'recruitingBusinessUnitNumber') ?? this.widgetConfig['widget-recruitingBusinessUnitNumber']?.toString();
      this.widgetConfig['widget-recruitingBusinessUnitNumber'] = recruitingBusinessUnitNumber ? Number.parseInt(recruitingBusinessUnitNumber, 10) : undefined;
      this.widgetConfig['widget-membershipid'] = this.getQueryOrValue('widget-membershipid', 'membershipid') ?? this.widgetConfig['widget-membershipid'];
      const membershipAmount = this.getQueryOrValue('widget-membershipamount', 'membershipamount') ?? this.widgetConfig['widget-membershipamount']?.toString();
      this.widgetConfig['widget-membershipamount'] = membershipAmount ? Number.parseInt(membershipAmount, 10) : undefined;
      const paymentMethod = this.getQueryOrValue('widget-paymentmethod', 'paymentmethod') ?? this.widgetConfig['widget-paymentmethod']?.toString();
      this.widgetConfig['widget-paymentmethod'] = paymentMethod ? Number.parseInt(paymentMethod, 10) : undefined;
      const isGift = this.getQueryOrValue('widget-isgift', 'isgift') ?? this.widgetConfig['widget-isgift']?.toString();
      this.widgetConfig['widget-isgift'] = isGift ? isGift === 'true' : undefined;
    },
    methods: {
      createWidgetEmbedSnippet(scriptQueries?: Record<string, string>) {
        const widgetConfig = this.widgetConfig as Record<string, any>;
        const widgetAttributes = Object.keys(widgetConfig)
          .filter((key) => widgetConfig[key] !== undefined)
          .map((key) => `data-${key}="${widgetConfig[key]}"`)
          .join('\n ');
        const queries = scriptQueries ? `?${Object.keys(scriptQueries).map((i) => `${i}=${scriptQueries[i]}`).join('&')}` : '';
        return `
          <link href="${this.widgetHost}/css/app.css" rel="stylesheet" />
          <div id="${widgetConfig['mount-id']}"></div>
            <script
              async="async"
              data-router-mode="abstract"
              data-config-path="${this.widgetHost}"
              data-auth-apikey="GL-*****************************"
              ${widgetAttributes}
              src="${this.widgetHost}/js/app.js${queries}"
            ><\/script>`
          .replace(/[ \t]+/g, ' ')
          .replace(/^[ \t]/gm, '')
          .trim();
      },
      /**
      * Gets a query value or if that is not set it will retrieve the route param value and then the config value (in that order).
      * The keys are case insensitive, so if there are keys with the same name but different casing the last one will be used.
      * @param configKey The key of the config value to retrieve
      * @param queryKey The key of the parameter or query value to retrieve, if this is not set the configKey will be used
      */
      getQueryOrValue(configKey: string, queryKey?: string): string | undefined {
        const configKeyLower = configKey.toLowerCase();
        queryKey = queryKey?.toLowerCase() ?? configKeyLower;
        const queries = Vue.$routerUtils.getQueryParams();
        const queriesLowerCase = {} as any;
        Object.keys(queries).forEach((queryKeyName) => {
          queriesLowerCase[queryKeyName.toLowerCase()] = queries[queryKeyName];
        });
        const value = (this.$config.values as any)[configKeyLower]?.toString() ?? Vue.$router.currentRoute.params[queryKey] ?? queriesLowerCase[queryKey];
        return value;
      },
      toggleWidgetEmbedInfo() {
        this.isWidgetEmbedInfoVisible = !this.isWidgetEmbedInfoVisible;
      },
      async openWidget(membershipid:string, recruitingBusinessUnitNumber:number, language:string, membershipAmount:number, paymentType:number, isGift:boolean) {
        const queries = {
          membershipid, language, recruitingBusinessUnitNumber: recruitingBusinessUnitNumber?.toString(), membershipAmount: membershipAmount?.toString(), paymentType: paymentType?.toString(), isGift: isGift?.toString(),
        } as Record<string, string>;
        const queryString = Object.keys(queries)
          .filter((key) => queries[key] !== undefined && queries[key] !== null && queries[key] !== '')
          .map((key) => `${key}=${encodeURIComponent(queries[key])}`)
          .join('&');
        this.link = new URL(`${this.widgetHost}/${this.widgetConfig.route}?${queryString}`, document.baseURI).href;
        this.widgetEmbedSnippet = this.createWidgetEmbedSnippet({ cacheBust: Date.now().toString() });
        this.$nextTick(() => {
          const resultElement = document.getElementById('result') as HTMLElement;
          resultElement.replaceChildren(document.createRange().createContextualFragment(this.widgetEmbedSnippet!));
        });
      },
    },
  });
  </script>
