// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import MembershipTemplateResourceViewModel from '../../../../viewModel/resource/MembershipTemplateResourceViewModel';
import MembershipTemplateResourceDTO from '../../dto/resource/MembershipTemplateResourceDTO';

export default abstract class MembershipTemplateResourceModel extends BaseModel<MembershipTemplateResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  * @type {double}
  */
  get minimumAnnualFee() { return this.dto.minimumAnnualFee; }
  set minimumAnnualFee(value) { this.dto.minimumAnnualFee = value; }
  /**
  */
  get isGiftMembershipAvailable() { return this.dto.isGiftMembershipAvailable; }
  set isGiftMembershipAvailable(value) { this.dto.isGiftMembershipAvailable = value; }
  /**
  */
  get hasPartnerAddress() { return this.dto.hasPartnerAddress; }
  set hasPartnerAddress(value) { this.dto.hasPartnerAddress = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.minimumannualfee);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.minimumAnnualFee = value;
    value = qs.isGiftMembershipAvailable === 'true' ? true : qs.isgiftmembershipavailable;
    value = qs.isGiftMembershipAvailable === 'false' ? false : undefined;
    this.dto.isGiftMembershipAvailable = value;
    value = qs.hasPartnerAddress === 'true' ? true : qs.haspartneraddress;
    value = qs.hasPartnerAddress === 'false' ? false : undefined;
    this.dto.hasPartnerAddress = value;
  }
}
