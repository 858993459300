<template>
  <p-row>
    <prona-wizard
      ref="wizard"
      class="wizard-animatura-registration"
      :title="title"
      :steps="steps"
      :initialize-data="initializeData"
      :service-function="serviceFunction"
      :model-class="model"
      is-admin-mode="true"
      to-on-complete="/"
    ></prona-wizard>
  </p-row>
</template>

<script lang="ts">
  import Wizard, { StepData } from '@/src/components/wizard/prona-wizard.vue';
  import AnimaturaRegistrationRequestViewModel from '@/src/services/v1/viewModel/resource/AnimaturaRegistrationRequestViewModel';
  import ContactAddressResourceViewModel from '@/src/services/v1/viewModel/resource/ContactAddressResourceViewModel';
  import ContactResourceViewModel from '@/src/services/v1/viewModel/resource/ContactResourceViewModel';

  import Vue from 'vue';
  import BillingAddressResourceViewModel from '@/src/services/v1/viewModel/resource/BillingAddressResourceViewModel';
  import GuidLookupResourceViewModel from '@/src/services/v1/viewModel/resource/GuidLookupResourceViewModel';
  import topicSelectionSchema from './1.animatura-topic-schema';
  import excursionDataSchema from './2.excursion-data-schema';
  import TeacherDataSchema from './3.teacher-data-schema';
  import schoolDataSchema from './4.school-data-schema';

  type WizardDataType = InstanceType<typeof Wizard>;

  export default Vue.extend({
    name: 'WizardAnimaturaRegistrationInternal',
    layout: 'layout-widget',
    props: {

    },
    data: () => ({
      steps: [] as StepData[],
      title: Vue.$t('pronatura.widget.animaturawizard.wizard_title'),
      animaturaTypeIdKey: 'animaturaTypeId',
      model: AnimaturaRegistrationRequestViewModel,
      animationLanguages: [] as Array<GuidLookupResourceViewModel>,
      translatedLanguages: [] as Array<GuidLookupResourceViewModel>,
      isAdminMode: true as boolean|undefined,
    }),
    computed: {

    },
    async mounted() {
      this.steps = [
        {
          breadcrumb: (wizardData: AnimaturaRegistrationRequestViewModel) => wizardData.selectedAnimaturaTopic?.title ?? Vue.$t('pronatura.widget.animaturawizard.topic_selection'),
          title: 'pronatura.widget.animaturawizard.topic_selection',
          component: () => import('./1.animatura-topic.vue'),
          schema: topicSelectionSchema,
        },
        {
          breadcrumb: 'pronatura.widget.animaturawizard.excursiondata',
          component: () => import('./2.excursion-data.vue'),
          schema: excursionDataSchema,
        },
        {
          breadcrumb: 'pronatura.widget.animaturawizard.teacher.title',
          component: () => import('./3.teacher-data.vue'),
          schema: TeacherDataSchema,
        },
        {
          breadcrumb: 'pronatura.widget.animaturawizard.school',
          component: () => import('./4.school-data.vue'),
          schema: schoolDataSchema,
        },
        {
          breadcrumb: 'pronatura.widget.animaturawizard.summary.title',
          component: () => import('./5.Animatura-overview.vue'),
          sendData: true,
        },
        {
          breadcrumb: 'pronatura.widget.animaturawizard.confirmation.title',
          component: () => import('./6.animatura-confirmation.vue'),
          disablePreviousSteps: true,
          clearProgressOnMounted: true,
        },
      ];
      const wizard = this.$refs.wizard as WizardDataType;
      wizard.$once('initialized', async (wizardData: AnimaturaRegistrationRequestViewModel) => {
        const animaturaTopicKey = wizardData.animaturaTopicKeyFromQuery;
        wizardData.isAdminMode = this.isAdminMode;
        if (this._.isSet(animaturaTopicKey)) {
          try {
            await wizard.clearProgress();
            wizardData.animationTopicKey = animaturaTopicKey;
            const currentQuery = this.$routerUtils.getQueryParams();
            delete currentQuery.animaturaTypeId;
            delete this.$route.query.animaturaTypeId;
            this.$routerUtils.updateQueryParams(currentQuery, { preserve: false });
            const selectedTopic = await this.$service.v1.api.widgetAnimaturaTopics.getAnimaturaTopicById(animaturaTopicKey);
            wizardData.selectedAnimaturaTopic = selectedTopic;
            wizard.saveWizardData();
            wizard.setStep(1);
          } catch (error: any) {
            this.$log.error(error);
            wizard.setStep(0);
          }
        }
      });
    },
    methods: {
      async serviceFunction(wizardData: AnimaturaRegistrationRequestViewModel) {
        await this.$service.v1.api.widgetAnimatura.createAnimaturaRegistration(wizardData);
      },
      async initializeData() {
        const initializedModel = new AnimaturaRegistrationRequestViewModel().fromModel({
          teacherContact: new ContactResourceViewModel().fromModel({
            address: new ContactAddressResourceViewModel(),
          }),
          schoolContact: new ContactResourceViewModel().fromModel({
            address: new ContactAddressResourceViewModel(),
          }),
          billingAddress: new BillingAddressResourceViewModel().fromModel({
            address: new ContactAddressResourceViewModel(),
          }),
        });
        await initializedModel.loadLanguages();

        initializedModel.isAdminMode = this.isAdminMode;
        return initializedModel;
      },
    },
  });
</script>
