<template>
  <prona-wizard-step class="wizard-step-animatura-order-overview" :proceed-label="$t('pronatura.widget.animaturawizard.summary.complete')" :custom-proceed-function="customProceed">
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div v-for="section in wizardData.summary.sections" :key="section.title" class="summary_tables">
      <strong>{{ section.title }}</strong>
      <table>
        <tr v-for="entry in section.items" :key="entry.label">
          <td v-if="$media.isNotMobile">
            {{ entry.label }}
          </td>
          <td>
            <span v-if="entry.showLabelOnMobile && $media.isMobile">{{ entry.label }}: </span>
            {{ entry.displayValue }}
          </td>
        </tr>
      </table>
    </div>
    <!-- <p-checkbox v-model="wizardData.acceptGdpr" :label="$tEnum('pronatura.widget.membershipwizard.accept_terms', 'no_html')">
      <span v-html="$tEnum('pronatura.widget.membershipwizard.accept_terms', 'html')"></span>
    </p-checkbox> -->
  </prona-wizard-step>
</template>

<script lang="ts">
  import AnimaturaRegistrationRequestViewModel from '@/src/services/v1/viewModel/resource/AnimaturaRegistrationRequestViewModel';
  import AnimaturaTopicResourceViewModel from '@/src/services/v1/viewModel/resource/AnimaturaTopicResourceViewModel';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'WizardStepAnimaturaOverview',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      selectedMembership: undefined as undefined |AnimaturaTopicResourceViewModel,
    }),
    computed: {
      wizardData: {
        get(): AnimaturaRegistrationRequestViewModel {
          return this.value ?? {};
        },
        set(value: AnimaturaRegistrationRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      await this.wizardData.loadLanguages();
    },
    methods: {
      customProceed() {
        this.$sessionStorage.set('create-transaction', true);
        this.proceedFunction!();
      },
    },
  });
</script>
