<template>
  <prona-wizard-step class="wizard-step-animatura-excursiondata">
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div class="pronatura-animatura-form">
      <p-row>
        <p-col xs12 md12>
          {{ $t('pronatura.widget.animaturawizard.excursion.introText') }}
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md12>
          <p-text-field v-model="wizardData.desiredDates" required :label="$t('pronatura.widget.animaturawizard.excursion.desireddates')" :messages="$t('pronatura.widget.animaturawizard.excursion.desireddates.description')"></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md12>
          <p-text-field v-model="wizardData.animationPlace" required :label="$t('pronatura.widget.animaturawizard.excursion.location')"></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md7>
          <p-select v-model="wizardData.animationLanguageId" required :label="$t('pronatura.widget.animaturawizard.excursion.language')" :items="wizardData.animationLanguages">
          </p-select>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md7>
          <p-text-field v-model="wizardData.schoolClass" required :label="$t('pronatura.widget.animaturawizard.excursion.schoolclass')" :messages="$t('pronatura.widget.animaturawizard.excursion.schoolclass.description')"></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md7>
          <p-text-field v-model="wizardData.countParticipants" required :label="$t('pronatura.widget.animaturawizard.excursion.participants')" :messages="$t('pronatura.widget.animaturawizard.excursion.participants.description')"></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md12>
          <p-textarea v-model="wizardData.animationNotes" :label="$t('pronatura.widget.animaturawizard.excursion.notes')"></p-textarea>
        </p-col>
      </p-row>
    </div>
  </prona-wizard-step>
</template>

<script lang="ts">
  import AnimaturaRegistrationRequestViewModel from '@/src/services/v1/viewModel/resource/AnimaturaRegistrationRequestViewModel';
  import AnimaturaTopicResourceViewModel from '@/src/services/v1/viewModel/resource/AnimaturaTopicResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardStepExcursionData',
    props: {
      value: { type: Object, default: undefined },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      selectedMembership: undefined as undefined |AnimaturaTopicResourceViewModel,
    }),
    computed: {
      wizardData: {
        get(): AnimaturaRegistrationRequestViewModel {
          return this.value ?? {};
        },
        set(value: AnimaturaRegistrationRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async mounted() {
      await this.wizardData.loadLanguages();
      const language = (Vue.$translation.get() ?? Vue.$config.values['language-default']);
      const translatedLanguage = this.wizardData.translatedLanguages.filter((l) => l.id?.toLowerCase() === language.toLowerCase())[0]?.caption;
      this.wizardData.animationLanguageId = this.wizardData.animationLanguages?.filter((l) => l.caption?.toLowerCase() === translatedLanguage?.toLowerCase())[0]?.id;
    },
  });
</script>
