<template>
  <prona-wizard-step class="wizard-step-membership-order-complete">
    <h4 class="text-color-success">
      <strong>{{ $t('pronatura.widget.membershipwizard.confirmation_title') }}</strong>
    </h4>
    <p class="mt-3" v-html="$t('pronatura.widget.membershipwizard.confirmation_lead')"></p>
    <template #step-actions>
      <div></div>
    </template>
  </prona-wizard-step>
</template>

<script lang="ts">
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import MembershipTemplateResourceViewModel from '@/src/services/v1/viewModel/resource/MembershipTemplateResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardStepMembershipOrderComplete',
    props: {
      value: { type: Object, default: undefined },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      selectedMembership: undefined as undefined |MembershipTemplateResourceViewModel,
    }),
    computed: {
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.value ?? {};
        },
        set(value: CreateMembershipRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async mounted() {
      // do not clear WidgetAnalyticsData: https://glittrsoftware.visualstudio.com/ProNatura.IL/_workitems/edit/9754
      // will be deleted on wizard entry
      const WidgetWindow = window as any;
      WidgetWindow.glitWidget = this.$sessionStorage.get<Object>('widget-analytics-window') ?? {};
    },
  });
</script>
